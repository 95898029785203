/* AboutUs.css */

.section {
  padding-top: 80px;
  padding-bottom: 80px;
  background-color: #f8f9fa;
}

.btn-custom-primary {
  background-color: #007bff;
  border: none;
  color: white;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 30px;
  transition: background-color 0.3s ease, transform 0.3s ease;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.btn-custom-primary:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}

.small-title {
  font-size: 18px;
  font-weight: 700;
  color: #6c757d;
  text-transform: uppercase;
}

.display-4 {
  font-size: 2.5rem;
  font-weight: 700;
  color: #212529;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
  color: #6c757d;
}

.text-muted {
  color: #6c757d !important;
}

.chart-container {
  position: relative;
  height: 300px;
}

.card-hover:hover {
  transform: translateY(-10px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.card-body h5 {
  font-size: 18px;
  font-weight: 700;
}

.card-body p {
  font-size: 14px;
  margin-bottom: 0;
}

.partner-logo {
  max-height: 50px;
  max-width: 100px;
  object-fit: contain;
  filter: grayscale(100%);
  transition: filter 0.3s ease;
}

.partner-logo:hover {
  filter: grayscale(0%);
}